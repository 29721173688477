<template>
    <div>
        <el-row class="first-row">
            <el-col :span="6">
                <div class="left-icon">
                    <i class="el-icon-caret-right"></i>
                </div>
                <div class="right-info">
                    <span>运行中：</span>
                    <span>{{running}}</span>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="left-icon" style="background-color: #F39C12;">
                    <i class="fa fa-exclamation-circle"></i>
                </div>
                <div class="right-info">
                    <span>告警：</span>
                    <span>{{warning}}</span>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="left-icon" style="background-color: #DD4B39;">
                    <i class="fa fa-times-circle"></i>
                </div>
                <div class="right-info">
                    <span>故障：</span>
                    <span>{{fault}}</span>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="left-icon" style="background-color: #3A8EE6;">
                    <i class="fa fa-cogs"></i>
                </div>
                <div class="right-info">
                    <span>工单：</span>
                    <span>{{order}}</span>
                </div>
            </el-col>
        </el-row>
        <el-row class="second-row">
            <el-col :span="12" class="first-col">
                <div>
                    <div style="font-size: 24px;color:#000;font-weight: bolder;">光伏概况</div>
                    <div class="left-report">
                        <div id="report1"></div>
                        <div>当前功率(kW)</div>
                    </div>
                    <div class="right-report">
                        <div id="report2"></div>
                        <div>当日发电量(kWh)</div>
                    </div>
                </div>
            </el-col>
            <el-col :span="12" class="second-col">
                <div>
                    <div style="font-size: 24px;color:#000;font-weight: bolder;">柴发概况</div>
                    <div class="left-report">
                        <div id="report5"></div>
                        <div>当前功率(kW)</div>
                    </div>
                    <div class="right-report">
                        <div id="report6"></div>
                        <div>当日发电量(kWh)</div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row class="third-row">
            <el-col :span="24" class="first-col" style="">
                <div>
                    <div id="report3" style="width: 100%;height: 330px;"></div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>

    import {queryDeviceCount} from "../../api/analysis/mainApi";
    import {queryWarningCount} from "../../api/analysis/mainApi";
    import {queryCurPower} from "../../api/analysis/mainApi";
    import {queryCurDayEnergy} from "../../api/analysis/mainApi";
    import {queryCurMonthDayEnergy} from "../../api/analysis/mainApi";
    let echarts = require('echarts');
    export default {

        name: "Main.vue",
        data() {

            return {

                running: 0,
                warning: 0,
                fault: 0,
                order: 0
            }
        },
        mounted() {

            // 初始化运行设备的数量
            queryDeviceCount().then((res) =>{

                if (res.data != null) {

                    this.running = res.data;
                }
            }).catch(err => console.error(err));
            // 初始化告警数量
            queryWarningCount().then((res) =>{

                if (res.data != null) {

                    this.warning = res.data;
                }
            }).catch(err => console.error(err));
            // 初始化光伏概况
            queryCurPower().then((res) =>{

                if (res.data != null) {

                    let option1 = {

                        series: [{

                            type: 'gauge',
                            radius:'95%',
                            center: ['50%','55%'],
                            detail: {

                                formatter: '{value}kW',
                                color: '#3A8EE6',
                                fontSize: 30,
                                fontWeight: 'bolder'
                            },
                            data: [{value: res.data.curPower / 1000}],
                            max: res.data.maxPower,
                            axisLine: {

                                show: true,
                                lineStyle: {

                                    width: 20,
                                    color: [[0.2, '#00A65A'], [0.8, '#3A8EE6'], [1, '#DD4B39']]
                                }
                            },
                            axisTick: {

                                show: false
                            },
                            splitLine: {

                                length: 20,
                                lineStyle: {

                                    color: 'rgba(255, 255, 255, 0.2)'
                                }
                            },
                            axisLabel: {

                                fontSize: 14
                            },
                            pointer: {

                                width: 10
                            }
                        }]
                    };
                    let myChart1 = echarts.init(document.getElementById('report1'));
                    myChart1.setOption(option1);

                    let option2 = {

                        series: [{

                            type: 'gauge',
                            radius:'95%',
                            center: ['50%','55%'],
                            detail: {

                                formatter: '{value}kW',
                                color: '#3A8EE6',
                                fontSize: 30,
                                fontWeight: 'bolder'
                            },
                            data: [{value: res.data.curEnergy}],
                            max: res.data.maxEnergy,
                            axisLine: {

                                show: true,
                                lineStyle: {

                                    width: 20,
                                    color: [[0.2, '#00A65A'], [0.8, '#3A8EE6'], [1, '#DD4B39']]
                                }
                            },
                            axisTick: {

                                show: false
                            },
                            splitLine: {

                                length: 20,
                                lineStyle: {

                                    color: 'rgba(255, 255, 255, 0.2)'
                                }
                            },
                            pointer: {

                                width: 10
                            }
                        }]
                    };
                    let myChart2 = echarts.init(document.getElementById('report2'));
                    myChart2.setOption(option2);
                }
            }).catch(err => console.error(err));
            // 初始化柴发概况
            queryCurDayEnergy().then((res) =>{

                if (res.data != null) {

                    let option5 = {

                        series: [{

                            type: 'gauge',
                            radius:'95%',
                            center: ['50%','55%'],
                            detail: {

                                formatter: '{value}kW',
                                color: '#3A8EE6',
                                fontSize: 30,
                                fontWeight: 'bolder'
                            },
                            data: [{value: res.data.curPower / 1000}],
                            max: res.data.maxPower,
                            axisLine: {

                                show: true,
                                lineStyle: {

                                    width: 20,
                                    color: [[0.2, '#00A65A'], [0.8, '#3A8EE6'], [1, '#DD4B39']]
                                }
                            },
                            axisTick: {

                                show: false
                            },
                            splitLine: {

                                length: 20,
                                lineStyle: {

                                    color: 'rgba(255, 255, 255, 0.2)'
                                }
                            },
                            pointer: {

                                width: 10
                            }
                        }]
                    };
                    let myChart5 = echarts.init(document.getElementById('report5'));
                    myChart5.setOption(option5);
                    let option6 = {

                        series: [{

                            type: 'gauge',
                            radius:'95%',
                            center: ['50%','55%'],
                            detail: {

                                formatter: '{value}kW',
                                color: '#3A8EE6',
                                fontSize: 30,
                                fontWeight: 'bolder'
                            },
                            data: [{value: res.data.curEnergy}],
                            max: res.data.maxEnergy,
                            axisLine: {

                                show: true,
                                lineStyle: {

                                    width: 20,
                                    color: [[0.2, '#00A65A'], [0.8, '#3A8EE6'], [1, '#DD4B39']]
                                }
                            },
                            axisTick: {

                                show: false
                            },
                            splitLine: {

                                length: 20,
                                lineStyle: {

                                    color: 'rgba(255, 255, 255, 0.2)'
                                }
                            },
                            pointer: {

                                width: 10
                            }
                        }]
                    };
                    let myChart6 = echarts.init(document.getElementById('report6'));
                    myChart6.setOption(option6);
                }
            }).catch(err => console.error(err));
            // 初始化月电量
            queryCurMonthDayEnergy().then((res) => {

                if (res.data != null) {

                    let option3 = {

                        title:{

                            text: '最近21天发电量趋势图',
                            textStyle: {

                                color: "#000",
                                fontSize: 24,
                                fontWeight: "bolder"
                            }
                        },
                        tooltip: {

                            trigger: 'axis',
                            axisPointer: {

                                type: 'shadow'
                            }
                        },
                        legend: {

                            data: ['柴发', '光伏', "负载"],
                            textStyle: {

                                color: '#000'
                            }
                        },
                        grid: {

                            top: 65,
                            bottom: 30,
                            left:50,
                            right:50
                        },
                        xAxis: {
                            data: res.data.dateArr.reverse(),
                            axisLabel: {
                                inside: false,
                                textStyle: {
                                    color: '#999'
                                }
                            },
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false
                            }
                        },
                        yAxis: {
                            name: 'kWh',
                            nameTextStyle: {

                                align: "right",
                                fontSize: 14,
                                color: "#999",
                                padding: [0, 8, 0, 0]
                            },
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                textStyle: {
                                    color: '#999'
                                }
                            }
                        },
                        series: [
                            {
                                name: "柴发",
                                data: res.data.genEnergyArr.reverse(),
                                type: 'bar',
                                barWidth: 10,
                                itemStyle: {

                                    color: "#3A8EE6"
                                }
                            },
                            {
                                name: "光伏",
                                data: res.data.pvEnergyArr.reverse(),
                                type: 'bar',
                                barWidth: 10,
                                itemStyle: {

                                    color: "#00A65A"
                                }
                            },
                            {
                                name: "负载",
                                data: res.data.loadEnergyArr.reverse(),
                                type: 'bar',
                                barWidth: 10,
                                itemStyle: {

                                    color: "#DD4B39"
                                }
                            }
                        ]
                    };
                    let myChart3 = echarts.init(document.getElementById('report3'));
                    myChart3.setOption(option3);
                }
            }).catch(err => console.error(err));
        }
    }
</script>
<style lang="scss" scoped>
    .first-row {

        margin:20px 0 0 20px;
        padding:0;
        height:100px;
        .el-col {

            padding-left: 0;
            padding-right: 15px;
            height:90px;
            line-height: 90px;
            .left-icon {

                font-size: 50px;
                color:white;
                vertical-align: middle;
                width:90px;
                background-color:#00A65A;
                display: inline-block;
                text-align: center;
            }
            .right-info {

                font-size:28px;
                color:#000;
                font-weight: bolder;
                vertical-align: middle;
                width: calc(100% - 90px);
                display: inline-block;
                background-color: #fff;
                span:first-child {

                    margin-left: 50px;
                }
            }
        }
    }
    .second-row {

        height: 380px;
        margin-left: 20px;
        .first-col {

            height:100%;
            padding:4px 8px 16px 0;
            div {

                background-color: #fff;
                .left-report {

                    width: 49%;
                    display:inline-block;
                    #report1 {

                        width: 100%;
                        height:300px;
                    }
                    div:nth-child(2) {

                        height:40px;
                        font-weight: bolder;
                        font-size: 24px;
                        text-align: center;
                    }
                }
                .right-report {

                    width: 50%;
                    height:300px;
                    display:inline-block;
                    #report2 {

                        width: 100%;
                        height:300px;
                    }
                    div:nth-child(2) {

                        height:40px;
                        font-weight: bolder;
                        font-size: 24px;
                        text-align: center;
                    }
                }
            }
        }
        .second-col {

            height:100%;
            padding:4px 8px 16px 0;
            div {

                background-color: #fff;
                .left-report {

                    width: 49%;
                    display:inline-block;
                    #report5 {

                        width: 100%;
                        height:300px;
                    }
                    div:nth-child(2) {

                        height:40px;
                        font-weight: bolder;
                        font-size: 24px;
                        text-align: center;
                    }
                }
                .right-report {

                    width: 50%;
                    height:300px;
                    display:inline-block;
                    #report6 {

                        width: 100%;
                        height:300px;
                    }
                    div:nth-child(2) {

                        height:40px;
                        font-weight: bolder;
                        font-size: 24px;
                        text-align: center;
                    }
                }
            }
        }
    }
    .third-row {

        margin-top: 7px;
        margin-left: 20px;
        margin-right: 6px;
        height: 360px;
        border: 0px solid red;
        background-color: white;
        .first-col {

            height:100%;
        }
    }
</style>
