import axios from 'axios';
import global from '../../components/Global';

let base = global.serverBase;

export const queryDeviceCount = params => {

    return axios.post(base + '/analysis/queryDeviceCount', params);
};

export const queryWarningCount = params => {

    return axios.post(base + '/analysis/queryWarningCount', params);
};
export const queryCurPower = params => {

    return axios.post(base + '/analysis/queryCurPower', params);
};
export const queryCurDayEnergy = params => {

    return axios.post(base + '/analysis/queryCurDayEnergy', params);
};
export const queryCurMonthDayEnergy = params => {

    return axios.post(base + '/analysis/queryCurMonthDayEnergy', params);
};
